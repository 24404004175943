<template>
  <div class="inspiration">
    <div class="inspiration-section">
      <div class="inspiration-container">
        <div class="w-layout-grid inspiration-grid resetMargin">
          <div
            id="w-node-29c7f0550358-c4213d8d"
            class="inspiration-sticky-menu inspiration"
          >
            <div class="sticky-menu-container inspiration">
              <div class="sticky-menu-header"></div>
              <div
                class="inspiration-sticky-items-container without-header inspiration"
              >
                <div class="inspiration-sticky-items-container inspiration">
                  <a
                    v-scroll-to="{
                      el: '#vedanta-academy',
                      offset: -120,
                      easing: [0.6, 0.8, 0.5, 1.1],
                      duration: 700,
                    }"
                    class="inspiration-sticky-item inspiration"
                    :class="vedantaScrolledIntoView ? 'current' : ''"
                  >
                    <div class="inspiration-sticky-item">VEDANTA ACADEMY</div>
                  </a>
                  <a
                    v-scroll-to="{
                      el: '#swamiji',
                      offset: -120,
                      easing: [0.6, 0.8, 0.5, 1.1],
                      duration: 700,
                    }"
                    class="inspiration-sticky-item inspiration"
                    :class="swamijiScrolledIntoView ? 'current' : ''"
                  >
                    <div class="inspiration-sticky-item">SWAMIJI</div>
                  </a>
                  <a
                    v-scroll-to="{
                      el: '#sunandaji',
                      offset: -120,
                      easing: [0.6, 0.8, 0.5, 1.1],
                      duration: 700,
                    }"
                    class="inspiration-sticky-item inspiration"
                    :class="sunandajiScrolledIntoView ? 'current' : ''"
                  >
                    <div class="inspiration-sticky-item">SUNANDAJI</div>
                  </a>
                  <a
                    v-scroll-to="{
                      el: '.study-classes',
                      offset: -120,
                      easing: [0.6, 0.8, 0.5, 1.1],
                      duration: 700,
                    }"
                    class="inspiration-sticky-item inspiration"
                    :class="booksScrolledIntoView ? 'current' : ''"
                  >
                    <div class="inspiration-sticky-item">BOOKS</div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            id="vedanta-academy"
            v-view="viewVedantaHandler"
            ref="vedantaDiv"
            class="side-grid-container w-node-d285ad8927fe-c4213d8d no-margin-on-top vedantaAcademyContainer"
          >
            <div class="title-text-container">
              <div class="title-text center-aligned inspiration">
                Vedanta Academy
              </div>
            </div>
            <div class="youtube-video-container">
              <div
                style="padding-top: 56.17021276595745%"
                id="w-node-442c8d250c39-c4213d8d"
                class="w-embed-youtubevideo youtube"
              >
                <iframe
                  src="https://www.youtube-nocookie.com/embed/zEQBBRnNUCE?rel=0&amp;controls=1&amp;autoplay=0&amp;mute=0&amp;start=0"
                  frameborder="0"
                  style="
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    pointer-events: auto;
                  "
                  allow="autoplay; encrypted-media"
                  allowfullscreen=""
                ></iframe>
              </div>
            </div>
            <div class="content-text-container inspiration">
              <div class="content-text left-aligned inspiration">
                Founded by A. Parthasarathy in 1988, the Vedanta Academy is a
                place dedicated to the study, research and dissemination of
                Vedanta Philosophy. It provides students a three-years full time
                residential course. And also offers corporate seminars, retreats
                for professionals and business persons, and an annual Youth
                Camp. Furthermore, guests can visit and participate in the daily
                schedule followed at the academy.<br />‍<br />For further
                details visit www.vedantaworld.org
              </div>
            </div>
          </div>
          <div
            id="swamiji"
            v-view="viewSwamijiHandler"
            ref="swamijiDiv"
            class="side-grid-container swamiji w-node-58789ea2e7ba-c4213d8d swamijiContainer"
          >
            <div class="title-text-container">
              <div class="title-text center-aligned inspiration">Swamiji</div>
            </div>
            <div class="youtube-video-container">
              <div
                style="padding-top: 56.17021276595745%"
                id="w-node-58789ea2e7bf-c4213d8d"
                class="w-embed-youtubevideo youtube"
              >
                <iframe
                  src="https://www.youtube-nocookie.com/embed/FNlISiuuVw4?rel=0&amp;controls=1&amp;autoplay=0&amp;mute=0&amp;start=0"
                  frameborder="0"
                  style="
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    pointer-events: auto;
                  "
                  allow="autoplay; encrypted-media"
                  allowfullscreen=""
                ></iframe>
              </div>
            </div>
            <div class="content-text-container inspiration">
              <div class="content-text left-aligned inspiration">
                Swami Parthasarathy, dearly and popularly known as Swamiji, is a
                pre-eminent philosopher and universal speaker. He has authored
                12 books explaining the philosophy of Vedanta in a systematic
                and methodic manner, of which 3 are best sellers. He has been
                imparting the knowledge for over 60 years. His lectures are
                electrifying and deeply impactful to the listeners. He has
                addressed various international institutions and business
                organizations including Young Presidents Organization, World
                Economic Forum, NASA, Microsoft, Harvard University, Aspen
                Institute.
              </div>
            </div>
          </div>
          <div
            id="sunandaji"
            v-view="viewSunandajiHandler"
            ref="sunandajiDiv"
            class="side-grid-container swamiji sunandaji w-node-42db4408939c-c4213d8d sunandajiContainer"
          >
            <div class="title-text-container">
              <div class="title-text center-aligned inspiration">Sunandaji</div>
            </div>
            <div class="youtube-video-container">
              <div
                style="padding-top: 56.17021276595745%"
                id="w-node-42db440893a1-c4213d8d"
                class="w-embed-youtubevideo youtube"
              >
                <iframe
                  src="https://www.youtube-nocookie.com/embed/zBbtrYrlLyQ?rel=0&amp;controls=1&amp;autoplay=0&amp;mute=0&amp;start=0"
                  frameborder="0"
                  style="
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    pointer-events: auto;
                  "
                  allow="autoplay; encrypted-media"
                  allowfullscreen=""
                ></iframe>
              </div>
            </div>
            <div class="content-text-container inspiration">
              <div class="content-text left-aligned inspiration">
                Sunandaji is the daughter-disciple of Swamiji. She has been
                studying and researching the knowledge of Vedanta for over 3
                decades. And has dedicated her life to disseminating the
                knowledge. She is also one of the world-renowned speakers on
                this subject lecturing a diverse group of people all around the
                world.
              </div>
            </div>
          </div>
          <div
            id="vedanta-academy"
            v-view="viewBooksContainerHandler"
            class="study-classes w-node-20616d73824e-c4213d8d studyClassesContainer"
          >
            <div class="title-text-container-2">
              <div class="title-text center-aligned inspiration">Books</div>
            </div>
            <div class="books-container">
              <div class="w-layout-grid books-grid">
                <div
                  id="w-node-1215638894b9-c4213d8d"
                  class="book-content-container"
                >
                  <div class="book-title-container">
                    <div class="book-title-text">
                      THE FALL OF THE HUMAN INTELLECT
                    </div>
                  </div>
                  <div class="book-description-container">
                    <div class="book-description-text">
                      The first title in an ordered series of eleven written
                      works by A. Parthasarathy, and recommended as a
                      &quot;first read&quot; introduction to Vedanta philosophy.
                    </div>
                  </div>
                </div>
                <a
                  id="w-node-cbd6c6674d14-c4213d8d"
                  href="https://www.vedantaworld.org/books-and-media/12-books/13-the-fall-of-the-human-intellect"
                  target="_blank"
                  class="book-album-cover w-inline-block"
                  ><img
                    src="images/fall-of-the-human-intellect.png"
                    loading="lazy"
                    alt=""
                    class="book-cover"
                /></a>
                <a
                  id="w-node-433723e6d9d9-c4213d8d"
                  href="https://www.vedantaworld.org/books-and-media/12-books/136-the-holocaust-of-attachment"
                  target="_blank"
                  class="book-album-cover w-inline-block"
                  ><img
                    src="images/the-holocaust-of-attachment.png"
                    loading="lazy"
                    alt=""
                    class="book-cover"
                /></a>
                <a
                  id="w-node-2b9a7e5bc557-c4213d8d"
                  href="https://www.vedantaworld.org/books-and-media/12-books/85-governing-business-relationships"
                  target="_blank"
                  class="book-album-cover w-inline-block"
                  ><img
                    src="images/governing-business-relationships.png"
                    loading="lazy"
                    alt=""
                    class="book-cover"
                /></a>
                <a
                  id="w-node-6d5ebd5cdd41-c4213d8d"
                  href="https://www.vedantaworld.org/books-and-media/12-books/14-sep"
                  target="_blank"
                  class="book-album-cover w-inline-block"
                  ><img
                    src="images/select-english-poems.png"
                    loading="lazy"
                    alt=""
                    class="book-cover"
                /></a>
                <a
                  id="w-node-3287f3e59b63-c4213d8d"
                  href="https://www.vedantaworld.org/books-and-media/12-books/86-vedanta-treatise-the-eternities"
                  target="_blank"
                  class="book-album-cover w-inline-block"
                  ><img
                    src="images/vedanta-treatise-the-eternities.png"
                    loading="lazy"
                    alt=""
                    class="book-cover"
                /></a>
                <a
                  id="w-node-cd4c659911ef-c4213d8d"
                  href="https://www.vedantaworld.org/books-and-media/12-books/84-bhaja-govindam"
                  target="_blank"
                  class="book-album-cover w-inline-block"
                  ><img
                    src="images/bhaja-govindam-atmabodha.png"
                    loading="lazy"
                    alt=""
                    class="book-cover"
                /></a>
                <a
                  id="w-node-b9868925e77b-c4213d8d"
                  href="https://www.vedantaworld.org/books-and-media/12-books/82-bhagavad-gita"
                  target="_blank"
                  class="book-album-cover w-inline-block"
                  ><img
                    src="images/bhagavad-gita.png"
                    loading="lazy"
                    alt=""
                    class="book-cover"
                /></a>
                <a
                  id="w-node-fbf67869a231-c4213d8d"
                  href="https://www.vedantaworld.org/books-and-media/12-books/80-choice-upanishads"
                  target="_blank"
                  class="book-album-cover w-inline-block"
                  ><img
                    src="images/choice-upanishads.png"
                    loading="lazy"
                    alt=""
                    class="book-cover"
                /></a>
                <a
                  id="w-node-f8a133ea328d-c4213d8d"
                  href="https://www.vedantaworld.org/books-and-media/12-books/176-thesis-on-god"
                  target="_blank"
                  class="book-album-cover w-inline-block"
                  ><img
                    src="images/thesis-on-god.png"
                    loading="lazy"
                    alt=""
                    class="book-cover"
                /></a>
                <a
                  id="w-node-f5569f7db210-c4213d8d"
                  href="https://www.vedantaworld.org/books-and-media/12-books/81-the-symbolism-of-hindu-gods-and-rituals"
                  target="_blank"
                  class="book-album-cover w-inline-block"
                  ><img
                    src="images/the-symbolism-of-hindu-gods-rituals.png"
                    loading="lazy"
                    alt=""
                    class="book-cover"
                /></a>
                <div
                  id="w-node-51043e3240c2-c4213d8d"
                  class="book-content-container"
                >
                  <div class="book-title-container">
                    <div class="book-title-text">
                      THE HOLOCAUST OF ATTACHMENT
                    </div>
                  </div>
                  <div class="book-description-container">
                    <div class="book-description-text">
                      The virus of attachment has reached epidemic proportions
                      and the world is in a state of emergency.
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-e766d500100e-c4213d8d"
                  class="book-content-container"
                >
                  <div class="book-title-container">
                    <div class="book-title-text">
                      GOVERNING BUSINESS &amp; RELATIONSHIPS
                    </div>
                  </div>
                  <div class="book-description-container">
                    <div class="book-description-text">
                      Businesspersons mechanically follow routine practices laid
                      down by self-styled business schools.
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-faea0d5c0171-c4213d8d"
                  class="book-content-container"
                >
                  <div class="book-title-container">
                    <div class="book-title-text">SELECT ENGLISH POEMS</div>
                  </div>
                  <div class="book-description-container">
                    <div class="book-description-text">
                      A. Parthasarathy explores key philosophical concepts
                      through the study of English poetry. The ancient wisdom of
                      the Himalayas meets the English classics. A must-have for
                      youngsters and lovers of literature.<br />
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-8d8492b116df-c4213d8d"
                  class="book-content-container"
                >
                  <div class="book-title-container">
                    <div class="book-title-text">
                      VEDANTA TREATISE : THE ETERNITIES
                    </div>
                  </div>
                  <div class="book-description-container">
                    <div class="book-description-text">
                      For those seeking answers on the purpose and ultimate goal
                      of human life, this is an intensive answer. The
                      Eternities: Vedanta Treatise is the seminal work by A.
                      Parthasarathy, and forms the core of the findings of over
                      60 years of research and study into the ancient wisdom of
                      the Himalayas.<br />
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-4c927b69afc3-c4213d8d"
                  class="book-content-container"
                >
                  <div class="book-title-container">
                    <div class="book-title-text">
                      BHAJA GOVINDAM &amp; ATMABODHA
                    </div>
                  </div>
                  <div class="book-description-container">
                    <div class="book-description-text">
                      The Bhaja Govindam highlights the two human motivations –
                      acquisition and enjoyment. Its thirty-one verses point out
                      the delusion in external pursuit and urges one to seek the
                      supreme Self.<br />
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-5af0fe223c18-c4213d8d"
                  class="book-content-container"
                >
                  <div class="book-title-container">
                    <div class="book-title-text">BHAGAVAD GITA</div>
                  </div>
                  <div class="book-description-container">
                    <div class="book-description-text">
                      The Gita brings to light the positive and negative
                      tendencies that lie within. They represent your higher
                      aspiration and lower desire.<br />
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-f5b911259ed4-c4213d8d"
                  class="book-content-container"
                >
                  <div class="book-title-container">
                    <div class="book-title-text">CHOICE UPANISHADS</div>
                  </div>
                  <div class="book-description-container">
                    <div class="book-description-text">
                      This book provides commentary on Kena, Isávásya, Kaivalya
                      and Mundaka Upanishads.<br />
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-3b6825819208-c4213d8d"
                  class="book-content-container"
                >
                  <div class="book-title-container">
                    <div class="book-title-text">THESIS ON GOD</div>
                  </div>
                  <div class="book-description-container">
                    <div class="book-description-text">
                      An exhaustive thesis on the nature of God for your study
                      and reflection.<br />
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-bfde22873518-c4213d8d"
                  class="book-content-container"
                >
                  <div class="book-title-container">
                    <div class="book-title-text">
                      THE SYMBOLISM OF HINDU GODS AND RITUALS
                    </div>
                  </div>
                  <div class="book-description-container">
                    <div class="book-description-text">
                      A practical text explaining the allegorical significance
                      of gods and goddesses; rituals and festivals, invocatory
                      prayers.<br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="w-node-15e2c8bec7f4-9e4ccf6c"
            class="inspiration-mobile-sticky-header-3"
          >
            <div
              @click="showMenuDropDown = !showMenuDropDown"
              data-hover=""
              data-delay="200"
              class="dropdown w-dropdown cursorLinkPointer"
            >
              <div class="dropdown-toggle w-dropdown-toggle">
                <div class="title-text-container inspiration">
                  <div
                    class="title-text center-aligned inspiration mobile-menu"
                  >
                    Inspiration
                  </div>
                </div>
                <div class="dropdown-arrow-3">
                 <img src="images/vedanta-spain-arrow-down.svg" loading="lazy" alt="" class="image-3">
                </div>
              </div>
              <nav
                class="dropdown-list inspiration-mobile w-dropdown-list"
                :class="showMenuDropDown ? 'w--open' : ''"
              >
                <a
                  v-scroll-to="{
                    el: '.vedantaAcademyContainer',
                    offset: -180,
                    easing: [0.6, 0.8, 0.5, 1.1],
                    duration: 700,
                  }"
                  class="inspiration-mobile-dropdown-item-2 w-dropdown-link"
                  >Vedanta Academy</a
                >
                <a
                  v-scroll-to="{
                    el: '.swamijiContainer',
                    offset: -180,
                    easing: [0.6, 0.8, 0.5, 1.1],
                    duration: 700,
                  }"
                  class="inspiration-mobile-dropdown-item-2 w-dropdown-link"
                  >Swamiji</a
                >
                <a
                  v-scroll-to="{
                    el: '.sunandajiContainer',
                    offset: -180,
                    easing: [0.6, 0.8, 0.5, 1.1],
                    duration: 700,
                  }"
                  class="inspiration-mobile-dropdown-item-2 w-dropdown-link"
                  >Sunandaji</a
                >
                <a
                  v-scroll-to="{
                    el: '.studyClassesContainer',
                    offset: -180,
                    easing: [0.6, 0.8, 0.5, 1.1],
                    duration: 700,
                  }"
                  class="inspiration-mobile-dropdown-item-2 w-dropdown-link"
                  >Books</a
                >
              </nav>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Inspiration',
  components: {
  },
  data () {
    return {
      vedantaScrolledIntoView: true,
      swamijiScrolledIntoView: false,
      sunandajiScrolledIntoView: false,
      booksScrolledIntoView: false,
      showMenuDropDown: false,
    }
  },
  mounted() {
    window.scrollTo(0, 0)
  },
  methods: {
    viewVedantaHandler (e) {
      console.log('Vedanta in View: ' + e.percentTop)
      if (e.type === 'exit') return
      if (e.percentInView >= 0.5) {
        this.vedantaScrolledIntoView = true
        this.swamijiScrolledIntoView = false
        this.sunandajiScrolledIntoView = false
        this.booksScrolledIntoView = false
      }
      if (e.percentInView < 0.5) {
        this.vedantaScrolledIntoView = false
      }
    },
    viewSwamijiHandler (e) {
      console.log('Swamiji in View: ' + e.percentTop)
      if (e.type === 'exit') return
      if (e.percentInView >= 0.5) {
        this.vedantaScrolledIntoView = false
        this.swamijiScrolledIntoView = true
        this.sunandajiScrolledIntoView = false
        this.booksScrolledIntoView = false
      }
      if (e.percentInView < 0.5) {
        this.swamijiScrolledIntoView = false
      }
    },
    viewSunandajiHandler (e) {
      console.log('Sunandda in View: ' + e.percentTop)
      if (e.type === 'exit') return
      if (e.percentInView >= 0.5) {
        this.vedantaScrolledIntoView = false
        this.swamijiScrolledIntoView = false
        this.sunandajiScrolledIntoView = true
        this.booksScrolledIntoView = false
      }
      if (e.percentInView < 0.5) {
        this.sunandajiScrolledIntoView = false
      }
    },
    viewBooksContainerHandler (e) {
      console.log('Books in View: ' + e.percentTop)
      if (e.type === 'exit') return
      if (e.percentInView >= 0.5) {
        this.vedantaScrolledIntoView = false
        this.swamijiScrolledIntoView = false
        this.sunandajiScrolledIntoView = false
        this.booksScrolledIntoView = true
      }
      if (e.percentInView < 0.5) {
        this.booksScrolledIntoView = false
      }
    }
  }
}
</script>
<style>
.resetMargin {
  margin-top: 30px !important;
}
@media screen and (max-width: 479px) {
.resetMargin {
    margin-top: 20px !important;
}
}
.inspiration-mobile a  {
  color: white !important;
}
.vedantaAcademyContainer {
  margin-top: 12px !important;
}
</style>
